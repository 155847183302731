.leaderboard-con {
  width: 100%;
  height: 100vh;
  background: #0d0d17;
}
.trophy-main-con {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 50%;
  gap: 50px;
  justify-content: space-between;
  .trophy-image {
    width: 319px;
    height: 309px;
  }
  .trophy-con {
  }
  .get-started-con {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 40px;
    .small-text {
      font-size: 16px;
      color: white;
      font-weight: 400;
    }
    .big-text {
      font-family: DM sans, sans-serif;
      font-size: 60px;
      font-weight: 700;
      color: #fdfdfd;
    }
  }
}

.list-main-con {
  width: 100%;
  height: 100vh;
  background: #0d0d17;
  padding-top: 50px;
  justify-content: space-around;
  display: flex;
  .leaderboard-list-con {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
    // height:fit-content;
    // border: 1px solid gray;
    padding: 0px 40px;
    .week-perks-con {
      display: flex;
      align-items: center;
      color: white;
      gap: 40px;
      .crown-image {
        width: 95px;
        height: 80px;
      }
      .diamond-con {
        display: flex;
        margin-left: auto;
        align-items: center;
        gap:5px;
        font-size:20px;
        font-weight: 700;
        padding: 6px 12px;
        border-radius: 4px;
        background: #6754f8;
        box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
      }
      .diamond-icon{
        width:34px;
        height:34px;
      }
    }
  }

  .rank-table-con {
    border-radius: 4px;
    height: fit-content;
    font-weight: 700;

    .table-header-con {
      border-radius: 12px;
      background: #20222b;
      color: white;
      display: flex;
      padding: 20px;
      font-size: 22px;
      line-height: 130%;
    }
    .name-con {
      flex: 1;
      padding-left: 100px;
    }
    .table-data-con {
      display: flex;
      color: white;
      align-items: center;
      justify-content: space-around;
      gap: 140px;
      border-bottom: 1px solid rgba(253, 253, 253, 0.4);
    }
    .profile-con {
      flex: 1;
      padding: 20px 0px;
    }
    .profile-pic-con {
      gap: 20px;
      align-items: center;
      display: flex;
    }
    .credits {
      justify-content: flex-end;
      padding-right: 20px;
    }
    .ranks {
      padding-left: 20px;
    }
  }
  .your-rank-con {
    color: white;
    font-weight: 700;
    width: 30%;
    padding-right: 20px;
    .black-card-con {
      .profile-details-con {
        border-radius: 24px 24px 0px 0px;
        background: rgba(19, 23, 34, 0.8);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid rgba(253, 253, 253, 0.4);
        .profile-con {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }
    }
    .learning-con {
      text-align: center;
      background: rgba(19, 23, 34, 0.8);
      padding-top: 20px;
      border-radius: 0px 0px 24px 24px;

      .keep-learning-con {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }
    }
  }
}
