.history-container {
  background: #0d0d17;
  padding: 30px;
  height: fit-content;
  .chat-input-con {
    border-radius: 12px;
    border: 0.4px solid rgba(255, 255, 255, 0.1);
    background: #131722;
    backdrop-filter: blur(5px);
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    transition:0.3s ease;
    .chat-input-text {
      background: #131722;
      color: #fff;
      font-family: Phantomsans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      border: none;
      width: 100%;
    }
  }
  .chat-input-con:focus-within {
    border-color: #6754f8;
  }
}

.day {
  color: #fdfdfd;
  padding: 16px;
  opacity: 0.5;
  font-size: 14px;
}
.history-content {
  background: #25252e;
}
.question-container {
  display: flex;
  justify-content: space-between;
  color: #fdfdfd;
  padding: 16px 16px 16px 16px;
  font-size: 20px;
  line-height: 140%;
  transition: 0.5s ease;
  cursor: pointer;
  .history-text {
    width: 100%;
  }
}
.question-container:hover {
  transform: translateY(-2px);
  transition: 0.3s ease;
  // border-bottom:1px solid #918fff;
  box-shadow: #918fff 0 0px 10px;
}

.answer-container {
  padding: 0px 16px 16px 16px;
  color: #fdfdfd;
  font-size: 20px;
  opacity: 0.6;
  line-height: 140%;
}
.check-primary {
  z-index: 100;
}
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #918fff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

input[type="checkbox"]:checked {
  border: none;
  background-image: url("../../../assets/icons/uil_check-circle.svg");
  background-size: 20px 20px;
  background-repeat: no-repeat;
}
input[type="checkbox"]:checked:after {
  display: block;
}

.history-list-con {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
