.h1-white {
  color: #fff;
  font-family: DM sans, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 112%;
  align-self: stretch;
}

.h2-white {
  color: var(--Text, #fdfdfd);
  font-family: DM sans, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.9;
}

.h3-white {
  color: #fdfdfd;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.h3-white-light {
  color: #fdfdfd;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.8;
  white-space: pre-wrap;
}

.h4-white {
  color: #fff;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h3-gray {
  color: #fdfdfd;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.5;
}

.h3-blue {
  font-family: DM sans, sans-serif;
  color: #a5a3ff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: auto;
}

.h3-white {
  font-family: DM sans, sans-serif;
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: auto;
}

.h4-blue {
  color: #5e5aff;
  font-family: DM sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto;
}

.h1-subtext {
  color: #fdfdfd;
  text-align: center;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.8;
}

.h2-subtext {
  color: var(--Text, #fdfdfd);
  font-family: DM Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.primary-btn {
  border-radius: 12px;
  opacity: 0.9;
  background: var(--Primary, #5e5aff);
  box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
  display: flex;
  width: 100px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    border-radius: 12px;
    opacity: 0.9;
    background: var(--Primary, #5e5aff);
    box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
    display: flex;
    width: 70px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.secondary-btn {
  border-radius: 12px;
  border: 1px solid #6754f8;
  opacity: 0.9;
  background: #191a21;
  box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
  display: flex;
  width: 100px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    border-radius: 12px;
    border: 1px solid #6754f8;
    opacity: 0.9;
    background: #191a21;
    box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
    display: flex;
    width: 70px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.btn-text {
  color: #fff;
  text-align: center;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-80 {
  margin-top: 60px;
}
.mb-60 {
  margin-bottom: 60px;
}

.plr-20 {
  padding: 0px 20px;
}

.df-sb {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

input {
  --webkit-appearance: none;
  box-shadow: none !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

:-webkit-autofill {
  color: #fff !important;
}

.loading {
  font-size: 16px;
  display: inline-block;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
  color: #fdfdfd;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  opacity: 0.8;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.sky-background {
  background: #1c55d5;
}

.blue-background {
  background: #5218fe;
}

.pink-background {
  background: #b418fe;
}
.orange-background {
  background: #d67455;
}

.dgb {
  background: rgba(37, 37, 46, 0.5);
}

.lgb {
  background: #25252e;
}

.gray-scale {
  filter: grayscale(100%);
  opacity: 0.6;
}
