.connected-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(19, 23, 34, 0.60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
    color:white;
}
.subtext {
    display:flex;
    justify-content: center;
    font-size: 12px;
}
.text{
    display:flex;
    justify-content: center;
    font-size:20px;
    font-weight: bold;
}