.navbar-con {
  border-bottom: 0.4px solid rgba(253, 253, 253, 0.6);
  background: rgba(19, 23, 34, 0.8);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 92px;
  position: relative;
  .profile-icon {
    width: 64px;
    cursor: pointer;
  }
  .cg-ai-icon {
    padding-left: 20px;
    cursor: pointer;
  }
  .cg-user-icon {
    margin-right:20px;
    cursor: pointer;
    height:auto;
    width: auto;
  }
  .cg-user-icon img {
    object-fit: contain;
    border-radius: 50px;
  }

  .user-menu-con {
    position: absolute;
    top: 30px;
    right: 80px;
  }

  .user-name-icon {
    position: absolute;
    top: 25px;
    right: 30px;
    background: #b418fe;
    border-radius: 50%;
    padding: 10px;
  }
}
