.mobile-sidepanel {
  position: absolute;
  left: 0;
  top:0;
  height: 100vh;
  width: 100%;
  z-index: 1500;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  backdrop-filter: blur(5px);
  .menu-con {
      display: flex;
      gap: 10px;
      padding-left: 10px;
      padding-top: 30px;
    }
}
.overlay-container {
    height:auto;
    background: #20222b;
    height:100vh;
    width:60%;
    padding-left:15px;
}


