.pg-con {
  padding: 40px 32px;
  border-radius: 12px;
  background: #25252e;
  display: flex;
  padding: 40px 32px;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;

  .cg-tip-con {
    border-radius: 12px;
    border: 0.4px solid rgba(253, 253, 253, 0.6);
    background: rgba(19, 23, 34, 0.2);
    backdrop-filter: blur(5px);
    padding: 24px;
  }
  .textarea{
    width:100%;
    border-radius: 6px;
    display:flex;
  }
  .textarea-big{
    height:150px;
  }
  textarea{
    width:100%;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #070B16;
    color:white;
    box-sizing: border-box;
    resize:none;
    padding:14px 24px;
    
  }
  
}