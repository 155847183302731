.explore-con {
  padding: 60px 60px 100px 60px;
  background: #0d0d17;
  overflow-y: scroll;
  height: calc(100vh - 200px);
  @media (max-width: 768px) {
    padding: 60px 10px;
  }

  .explore-black-card-con {
    margin-top: 0px;
    padding-top: 40px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    @media (max-width: 768px) {
      overflow-x: auto;
      gap: 20px;

      -ms-overflow-style: none; /* For IE and Edge */
      scrollbar-width: none; /* For Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .black-card-con {
      position: relative;
      width: 300px;
      padding: 20px 12px;
      border-radius: 12px;
      border: 0.4px solid rgba(253, 253, 253, 0.6);
      background: rgba(19, 23, 34, 0.2);
      backdrop-filter: blur(5px);
      cursor: pointer;
      transition: 0.3s ease;
      @media (max-width: 768px) {
        flex: 0 0 60%; /* Set the width to 60% in mobile view */
      }
    }

    .black-card-con:hover {
      box-shadow: #5e5aff 0 1px 10px;
      border: 0.4px solid #5218fe;
      transition: 0.3s ease;
    }
    .black-card-con:hover {
      transform: scale(1.06);
      transition: transform 0.3s ease;
    }

    .black-card-con:hover + .top-logo img {
      transform: rotate(25deg);
      transition: transform 0.3s ease;
    }

    .black-card-con .top-logo img {
      transform: rotate(0deg);
      transition: transform 0.3s ease;
    }

    .image-top {
      transition: transform 0.3s ease;
    }

    .image-top {
      position: absolute;
      height: 80px;
      right: -35px;
      bottom: 50px;
      @media (max-width: 768px) {
        top: -35px;
      }
    }
  }
}
.trading-strategies-gray-card-con {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.gray-link-card-con {
  border-radius: 12px;
  background: #25252e;
  padding: 40px 20px;
  position: relative;
  .get-started {
    display: flex;
    justify-content: space-between;
    .cg-logo-con {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .get-started-button:hover + .cg-logo-con img {
    transform: rotate(25deg);
    transition: transform 0.3s ease;
  }

  .get-started .cg-logo-con img {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
  }
}
.feedback-card {
  padding: 50px 32px;
  border-radius: 12px;
  background: var(--Primary, #5e5aff);
}

.action-card-con {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-x: auto;
    -ms-overflow-style: none; /* For IE and Edge */
    scrollbar-width: none; /* For Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .coloured-card-con {
    width: 30%;
    padding: 16px 32px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s ease;
    position: relative;
    min-height: 120px;
    .trading-image {
      position: absolute;
      left: 10px;
      bottom: 10px;
    }
    @media (max-width: 768px) {
      flex: 0 0 60%; /* Set the width to 100% in mobile view */
    }
  }
  .coloured-card-con:hover {
    transform: translateY(-4px);
    transition: 0.3s ease;
  }
}

.btn-section {
  display: flex;
  gap: 20px;
}
.button-28 {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fdfdfd;
  border-radius: 15px;
  box-sizing: border-box;
  color: #fdfdfd;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 50px;
  min-width: 0;
  outline: none;
  padding: 0px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #0d0d17;
  background-color: #fdfdfd;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}
