.modal-content {
  text-align: center;
  border-radius: 10px;
}
.cross {
  color: white;
  width: 31%;
  border-radius: 50px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.modal {
  display: flex;
  flex-direction: column;
}
.cross-logo {
  padding: 4px;
  border: 1px solid gray;
  background-color: #22222c;
  border-radius: 50px;
}
