html {
  height: 100%;
}
body {
  background: rgb(116, 63, 224);
  background: radial-gradient(
    circle,
    rgba(116, 63, 224, 1) 0%,
    rgba(13, 13, 23, 1) 100%
  );
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.content {
  display: flex;
  justify-content: center;
  gap: 20px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.first-con,
.second-con {
  box-sizing: border-box;
  padding: 5px;
}
@media (max-width: 768px) {
  .first-con {
    display: flex;
    justify-content: flex-end;
  }
}
.second-con {
  display: flex;
}
.first-con {
  position: relative;
  height: 300px;
  .ai-con {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}
.home-image-con {
  position: absolute;
  bottom: 10px;
  left: -170px;
  z-index: 4;
  @media (max-width: 768px) {
    left: 50%; /* Center the element horizontally */
    transform: translateX(50%); /* Adjust to center based on element's width */
    bottom: 20px;
  }
}
.home-image {
  width: 314px;
  height: 314px;
}
.finger-con {
  position: absolute;
  left: -150px;
  top: 0;
  z-index: 1;
  @media (max-width: 768px) {
    left: 50%; /* Center the element horizontally */
    transform: translateX(50%); /* Adjust to center based on element's width */
    top: 20px; /* Adjust the top position as needed */
    z-index:-5;
  }
}
.finger-image {
  width: 194px;
  height: 194px;
  
}

.elements-con-1,
.elements-con-2 {
  border-radius: 12.602px;
  border: 1.05px solid var(--stroke-style-1, rgba(255, 255, 255, 0.05));
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(35.68290328979492px);
  display: flex;
  width: 236.507px;
  height: 59.499px;
  padding: 11.363px 9.09px;
  align-items: center;
  gap: 12.602px;
  flex-shrink: 0;
}
.elements-con-2 {
  height: 100px;
  z-index: 5;
}

.first {
  display: flex;
  justify-content: center;
}


@media(max-width:768px) {
    .mobile-btn {
      width:100%;
    }
}
