.mobile-navbar-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  background: black;
}
.nav-title {
  display: flex;
  align-items: center;
  gap: 4px;
}

