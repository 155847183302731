.sidepanel-container {
  padding: 64px 0px;
  background: rgba(19, 23, 34, 0.8);
  backdrop-filter: blur(10px);
  height: 100vh;
  
  .menu-con {
    display: flex;
    gap: 20px;
    padding: 10px 0px;
    align-items: center;
    cursor: pointer;
    transition:0.3s ease;
  }
  .menu-con:hover {
    transform: translateY(-2px);
    text-shadow: 2px 2px 0px #6754f8;
    transition: 0.3s ease;
  }
  .menu-icon-con {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .border-left {
    width:7px;
    height:50px;
    border-radius: 10px;
    background: #6754f8;
  }
  .border-blank {
    width:7px;
    height:50px;
    border-radius: 10px;
  }
}
