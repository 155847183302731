.chat-qna-con {
  margin-bottom: 120px;
}

.chat-con {
  width: 100%;
  height: 100%;
  background: #0d0d17;
  position: relative;
  .CG-watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.09;
  }

  .normal-menu-con {
    position: absolute;
    top: 80px;
    right: 60px;
    @media (max-width: 768px) {
      right: 10px;
    }
  }
  .chat-subnav-con {
    display: flex;
    height: 64px;
    padding: 0px 60px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    background: #181920;

    @media (max-width: 768px) {
      height: 50px;
      padding: 10px;
    }
    .chat-option-icon-con {
      cursor: pointer;
    }

    .chat-subnav-title {
      color: #fdfdfd;
      font-family: Phantomsans, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .mobile-chat-content-con {
    padding: 20px 10px 0px 10px;
    overflow-y: scroll;
    position: relative;
    .chat-top-con {
      overflow-y: scroll;
      height: calc(100vh - 250px);
      width: 100%;
      position: relative;
      .chat-bottom-card-con {
        position: absolute;
        bottom: 80px;
        width: 97%;
        display: flex;
        justify-content: space-between;
        gap: 12px;
        margin-top: 120px;
        overflow-x: scroll;
        -ms-overflow-style: none; /* For IE and Edge */
        scrollbar-width: none; /* For Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .chat-button-con {
        position: absolute;
        bottom: 80;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        width: min-content;
        margin-left: auto;
        margin-right: auto;
        color: white;
        @media (max-width: 768px) {
          width: 100%;
          justify-content: space-around;
        }
      }
    }
    .chat-bottom-con {
      position: absolute;
      bottom: 0;
      width: 97%;
      height: auto;
      // border-radius: 12px;

      .chat-input-con {
        border-radius: 12px;
        border: 0.4px solid rgba(255, 255, 255, 0.1);
        height: auto;
        background: #131722;
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        transition: 0.3s ease;
        width: 90%;
        // margin: 0px 10px;

        .chat-input-text {
          resize: none;
          color: #fff;
          background: #131722;
          font-family: "DM sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          border: none;
          width: 100%;
          line-height: 140%;
        }
      }
      .chat-input-con:focus-within {
        border-color: #6754f8; /* Change the border color to blue when the textarea or any child element is focused */
      }
    }
  }

  .chat-contenet-con {
    padding: 20px 20px 60px 20px;
    overflow-y: scroll;
    height: calc(100vh - 246px);
    position: relative;
    .chat-top-con {
      overflow-y: scroll;
      height: 90%;
      place-content: space-between;
      position: relative;
      .chat-bottom-card-con {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        position: absolute;
        bottom: 0;
        padding: 0px 10px;
        overflow-x: scroll;
        width: 97%;
        -ms-overflow-style: none; /* For IE and Edge */
        scrollbar-width: none; /* For Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .chat-button-con {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        width: min-content;
        margin-left: auto;
        margin-right: auto;
        color: white;
      }
    }
    .chat-bottom-con {
      position: absolute;
      bottom: 10px;
      width: 95%;
      height: auto;
      border-radius: 12px;

      .chat-input-con {
        border-radius: 12px;
        border: 0.4px solid rgba(255, 255, 255, 0.1);
        height: auto;
        background: #131722;
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        transition: 0.3s ease;

        .chat-input-text {
          // max-height: 200px;
          resize: none;
          color: #fff;
          background: #131722;
          font-family: "DM sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          border: none;
          width: 100%;
          line-height: 140%;
        }
      }
      .chat-input-con:focus-within {
        border-color: #6754f8; /* Change the border color to blue when the textarea or any child element is focused */
      }
    }
  }
}

.chat-card-con {
  border-radius: 12px;
  border: 0.4px solid rgba(253, 253, 253, 0.3);
  background: rgba(19, 23, 34, 0.2);
  backdrop-filter: blur(5px);
  padding: 16px;
  height: fit-content;
  width: 234px;
  height: 70px;
  cursor: pointer;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
}
.chat-card-con:hover {
  box-shadow: 0 0 10px #6754f8;
}

.qna-section {
  width: auto;
  font-weight: 300;
  letter-spacing: 0.02em;
}
.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.user-name {
  color: #fdfdfd;
  opacity: 0.4;
  font-family: Phantomsans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.user-details {
  display: flex;
  gap: 10px;
  padding: 20px;
}
.question-section {
  width: 100%;
  color: white;
}

.cg-answers {
  color: white;
  background-color: #131722;
  display: flex;
  gap: 10px;
  padding: 20px 20px 60px 20px;
  opacity: 0.8;
}
.cg-logo {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}
.answer-section {
  width: 100%;
}
.answer {
  border-bottom: 1px solid gray;

  .loading::after {
    content: "";
    width: 5px;
    height: 20px;
    background: #5e5aff;
    display: inline-block;
    animation: cursor-blink 1s steps(2) infinite;
  }
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.links a {
  text-decoration: none;
  color: #918fff;
}
.links {
  text-decoration: none;
  color: #918fff;
}

.like-dislike-con {
  position: absolute;
  right: 20px;
  top: -20px;
  display: flex;

  .left-icon-con {
    padding: 8px 8px 4px 8px;
    border-radius: 4px 0px 0px 4px;
    border: 0.4px solid rgba(255, 255, 255, 0.1);
    background: #191a21;
    opacity: 0.9;
  }

  .right-icon-con {
    padding: 8px 8px 4px 8px;
    border-radius: 0px 4px 4px 0px;
    border: 0.4px solid rgba(255, 255, 255, 0.1);
    background: #191a21;
    opacity: 0.9;
  }
}

.answer-main-con {
  position: relative;
  .chatQna-button-con {
    display: flex;
    gap: 4px;
    position: absolute;
    right: 20px;
    bottom: 12px;
    .icon-button-con {
      padding: 4px 10px;
      border-radius: 4px;
      border: 0.4px solid rgba(255, 255, 255, 0.1);
      opacity: 0.9;
      background: #191a21;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      transition: 0.3s ease;

      .icon-text {
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .icon-button-con:hover {
      box-shadow: #6754f8 0 0.2px 8px;
      transform: translate(-2px);
    }
    .icon-button-con:active {
      box-shadow: none;
      transform: translateY(0);
    }
  }
}

.stop-button-con {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #6754f8;
  opacity: 0.9;
  background: #191a21;
  box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  transition: 0.3s ease;
}
.stop-button-con:hover {
  transform: translateY(-2px);
  box-shadow: #5e5aff 0 3px 15px;
}
.stop-button-con:active {
  transform: translate(0px);
  box-shadow: none;
}
