.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s ease;
}

.hidden:nth-child(1) {
  transition-delay: 200ms;
}
.hidden:nth-child(2) {
  transition-delay: 400ms;
}
.hidden:nth-child(3) {
  transition-delay: 600ms;
}
.hidden:nth-child(4) {
  transition-delay: 800ms;
}

.rotate-on-hover:hover + .top-log img {
  transform: rotate(25deg);
  transition: transform 0.3s ease;
}

.get-started .cg-logo-con img {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.float-up {
  animation: floatUp 1s ease; /* Adjust the duration as needed */
}

@keyframes floatUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
    filter: blur(5px);
  }
  100% {
    transform: translateY(0); /* Adjust the float distance as needed */
    opacity: 1;
  }
}

.floating {
  animation: floatUpDown 1.5s infinite alternate; /* Adjust the duration as needed */
}

@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px); /* Adjust the float distance as needed */
  }
}

@media (prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}
