.option-menu-con {
  width: 218px;
  background: #181920;
  border-radius: 8px;
  .menu-title-con {
    padding: 8px 16px;
    border-bottom: 0.4px solid rgba(253, 253, 253, 0.1);
    cursor: pointer;
    
  }
  .menu-title-con:hover {
    transform: scale(1.06);
    transition: 0.3s ease;
    text-shadow: 2px 2px rgb(13, 0, 255);
  }
}
