.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .main-con {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    overflow: hidden;

    .sidepanel-con {
      width: 20%;
      height: calc(100vh - 92px);
      background: rgba(19, 23, 34, 0.8);
      backdrop-filter: blur(10px);
    }
    .page-view-con {
      width: 80%;
    }
  }
}
.mobile-page-view-con {
  height: calc(100vh - 74px);
}
.main-con {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* .mobile-bottom-panel-con {

} */

.button-63 {
  align-items: center;
  background: linear-gradient(
    90deg,
    #743fe0 5.32%,
    #5e5aff 95.99%,
    #5e5aff 95.99%
  );

  border: 0;
  border-radius: 8px;
  /* box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15); */
  box-shadow: rgba(0, 0, 0, 0.25) 2px 0px 15px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: DM sans, sans-serif;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 15px 19px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
}

.button-63:hover {
  outline: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}
.button-63:active {
  box-shadow: none;
  transform: translateY(0);
}
@media (min-width: 768px) {
  .button-63 {
    min-width: 196px;
  }
}
@media (max-width: 432px) {
  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

/* CSS */
.danger {
  background: var(--Negative, #cc2b2d);
  border-radius: 11px;
  padding: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: DM sa;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
  text-underline-offset: 1px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  border: 0;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  will-change: transform;
}

.danger:hover {
  outline: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}
.danger:active {
  box-shadow: none;
  transform: translateY(0);
}

.primary:hover {
  border-bottom-style: none;
  border-color: #dadada;
}
.primary {
  background: #7876ff;
  border-radius: 11px;
  padding: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: DM sans, sans-serif;
  font-weight: 700;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px;
  text-underline-offset: 1px;
  transition: all 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  word-break: break-word;
  border: 0;
}

.primary:hover {
  outline: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}
.primary:active {
  box-shadow: none;
  transform: translateY(0);
}

.primary:hover {
  border-bottom-style: none;
  border-color: #dadada;
}

.trading-type-con {
  border-radius: 12px;
  border: 1px solid #6754f8;
  opacity: 0.9;
  background: #191a21;
  box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
  font-size: 12px;
  padding:5px 10px;
  width: fit-content;
}
