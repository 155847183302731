.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;


}

.landing-content {
    padding: 20px;
}

.text {
    font-size: 12px;
}
p{
    font-size: 18px;
    margin:0;
    padding:0;
}

.wallet-con{
    display: flex;
    justify-content: space-between;
    background-color: #1d1e28;
    margin-top:10px;
    border-radius: 12px;
    border: 1px solid #5B42F3;
    padding:10px;
    
}
.recommended {
    border:1px solid #6754f8;
    padding:01px 10px 0px 10px;
    font-size:12px;
    border-radius: 40px;
}
.confirm-button {
    margin-top:30px;
}

.try-text{
    padding:12px;
    background-color: #1d1e28;
    border-radius: 12px;
    font-size: 12px;
}

