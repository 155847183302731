.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(19, 23, 34, 0.6);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
 
}
.connect-con {
  display: flex;
  justify-content: space-between;
  font-family: DM sans, sans-serif;
  font-size: 25px;
}

.modal-content {
  background-color: #22222c;
  color: white;
  padding: 50px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 478px;
  gap: 10px;
  width: 25%;
  @media(max-width:768px) {
    width:65%;
  }
}

.wallet {
  border-radius: 4px;
  border: 1px solid rgba(132, 132, 132, 0.2);
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.wallet:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 20px;
}
.close-button {
  cursor: pointer;
}

.wallet-not-detected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.trust-wallet-logo {
  height: 134px;
  width: 134px;
}
.others {
  margin-top: 40px;
  text-align: center;
  border-bottom: 1px solid rgba(132, 132, 132, 0.2);
  padding-bottom: 4px;
}
