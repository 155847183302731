.mobile-bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  background: rgba(19, 23, 34, 0.8);
  backdrop-filter: blur(20px);
  padding: 10px 0px;

  .mobile-menu-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px 0px 30px;

    .active-menu {
      width: 50px;
      height: 7px;
      border-radius: 10px;
      background: #6754f8;
    }
    .inactive-menu {
      width: 50px;
      height: 7px;
      border-radius: 10px;
    }
  }
}
