.refer-con {
  background: #0d0d17;
  height: calc(100vh - 150px);
  border: 2px solid #0d0d17;
  padding: 40px;
  overflow-y: scroll;
  .header {
    text-align: center;
    margin-top: 20px;
  }
  .sub-header {
    font-weight: 400;
    text-align: center;
    line-height: 140%;
  }
  .referal-con {
    display: flex;
    gap: 10px;
  }

  .share {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: linear-gradient(
      90deg,
      #743fe0 5.32%,
      #5e5aff 95.99%,
      #5e5aff 95.99%
    );
    box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
    border: none;
  }
  .refer-code {
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: #070b16;
    width: 90%;
    color: #fdfdfd;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
  .cg-tokens-con {
    margin-top: 50px;
    border-radius: 12px;
    background: rgba(165, 163, 255, 0.1);
    backdrop-filter: blur(5px);
    display: flex;
    padding: 0px 16px 0px 16px;
  }
  .redeem-con {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .tokens-con {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;

    .token-text {
      color: #fdfdfd;
      opacity: 0.4;
      font-size: 16px;
    }
    .tokens {
      font-size: 25px;
    }
  }
  .reward-con {
    display: flex;
    gap: 40px;
    align-items: center;
    overflow-x: scroll;

    .refer-card {
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      padding: 12px 50px;
      border-radius: 12px;
      background: var(
        --Gradient,
        linear-gradient(90deg, #743fe0 5.32%, #5e5aff 95.99%, #5e5aff 95.99%)
      );
      box-shadow: 2px 4px 10px 2px rgba(157, 110, 252, 0.15);
    }
    .logo-container {
      display: flex;
      flex-wrap: nowrap;
      gap: 5px;
    }
    .referal-count {
      font-size: 16px;
      font-weight: 400;
    }
    .refer-card-gray {
      height: fit-content;
      width: fit-content;
      border-radius: 9px;
      background: rgba(157, 110, 252, 0.15);
      mix-blend-mode: luminosity;
      padding: 8px 25px;
      opacity: 0.3;
    }
  }
  .referral-con {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create two columns */
    grid-template-rows: repeat(2, auto); /* Create two rows */
    gap: 10px; /* Add spacing between grid items */
  }
  .my-referral-card {
    border-radius: 12px;
    border: 0.4px solid rgba(253, 253, 253, 0.6);
    background: rgba(19, 23, 34, 0.2);
    backdrop-filter: blur(5px);
    padding: 12px 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    .round-con {
      display: flex;
      width: 48.221px;
      height: 48.221px;
      border-radius: 50%;
      background: var(
        --Gradient,
        linear-gradient(90deg, #743fe0 5.32%, #5e5aff 95.99%, #5e5aff 95.99%)
      );
    }
    .round-image {
    }
    .friend-name {
      font-size: 16px;
      color: #fdfdfd;
    }
    .steps-con {
      color: #fdfdfd;
      font-size: 14px;
      opacity: 0.6;
    }
    .remind-link {
      display: flex;
      justify-content: flex-end;
      flex: 1;
    }
  }
}
